import React from 'react';
import styled from 'styled-components';
import {
  RiInstagramLine,
  RiFacebookFill,
  RiLinkedinFill,
} from 'react-icons/ri';

const SocialContainer = styled.div`
  display: flex;
  gap: 6px;
  font-size: 25px;
  color: var(--navy2);
  & > a {
    all: unset;
  }
  & > * {
    cursor: pointer !important;
    transition: all 500ms ease !important;
  }

  & > *:hover {
    transform: translateY(-4px);
  }
`;

const SocialMedia = ({ isMobile, size }) => {
  return (
    <SocialContainer isMobile={isMobile} style={size}>
      <a
        href='https://www.instagram.com/coachingprzyszlosci/'
        target={isMobile ? true : '_blank'}
        rel='noreferrer'
      >
        <RiInstagramLine />
      </a>
      <a
        href='https://www.facebook.com/coachingprzyszlosci/'
        target={isMobile ? true : '_blank'}
        rel='noreferrer'
      >
        <RiFacebookFill />
      </a>
      <a
        href='https://www.linkedin.com/in/dorota-ko%C5%9Bcielska-bronsztejn-6a7223253/'
        target={isMobile ? true : '_blank'}
        rel='noreferrer'
      >
        <RiLinkedinFill />
      </a>
    </SocialContainer>
  );
};

export default SocialMedia;
