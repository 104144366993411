import React from 'react';
import Layout from './src/components/Layout';
import { ParallaxProvider } from 'react-scroll-parallax';
import { gsap } from 'gsap';

export function wrapPageElement({ element, props }) {
  return (
    <ParallaxProvider>
      <Layout {...props}>{element}</Layout>
    </ParallaxProvider>
  );
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

export const onRouteUpdate = ({ location }) => {
  // GSAP animation for page enter
  gsap.from('body', {
    opacity: 0,
    duration: 1.5,
    stagger: 0.3,
  });
};
