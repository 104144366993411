import { createGlobalStyle } from 'styled-components';
import serif from '../fonts/DM_Serif_Text/DMSerifText-Regular.ttf';
import serifItalic from '../fonts/DM_Serif_Text/DMSerifText-Italic.ttf';
import text from '../fonts/Montserrat/Montserrat-VariableFont_wght.ttf';
import textItalic from '../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf';
import kalnia from '../fonts/Kalnia/Kalnia-VariableFont_wdth,wght.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Serif;
    src: url(${serif});
  }
  @font-face {
    font-family: Montserrat;
    src: url(${text});
  }
  @font-face {
    font-family: MontserratItalic;
    src: url(${textItalic});
  }

  @font-face {
    font-family: SerifItalic;
    src: url(${serifItalic});
  }

  @font-face {
    font-family: Handwriting;
    font-weight: 400;
    src: url(${kalnia});
  }



  p, input {
    font-family: Montserrat;
    color: var(--navyLight);
    font-weight: 400;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.6;
    letter-spacing: 0.05em;
    

    @media screen and (max-width: 1536px) { 
      font-size: .9rem;
    }
    @media screen and (max-width: 768px) {
      font-size: .8rem;
    }
    @media screen and (max-width: 640px) {
      font-size: .65rem;
    }

  }

  .tiles {
    display: inline;
    font-size: 16px;

    @media screen and (max-width: 1400px) { 
      font-size: 13px;
    }
    @media screen and (max-width: 480px) {
      font-size: 11px;
    }
    @media screen and (max-width: 390px) {
      font-size: 10px;
    }
  }


  label {
    font-family: Montserrat;
    color: var(--blue);
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    

    @media screen and (max-width: 1400px) { 
      font-size: 16px;
    }
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
    @media screen and (max-width: 390px) {
      font-size: 12px;
    }

  }
  .italic {
      font-family: MontserratItalic !important;
      display: inline;
    }

  .bold {
    font-weight: 550;
    display: inline;
  }


  .colored {
    background: var(--gradientLight);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline;
  }

  .colored2 {
    background: var(--gradientDark);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    //display: inline;
  }
 

  // h1 {
  //   font-family: Serif;
  //   color: var(--white);
  //   font-size: 80px;
  //   text-shadow: 0px 0px 10px rgba(0, 19, 55, .5);

  //   line-height: 1.4;
  //   letter-spacing: 0.02em;

  //   @media screen and (max-width: 1400px) { 
  //     font-size: 75px;
  //   }
  //   @media screen and (max-width: 480px) {
  //     font-size: 35px;
  //   }
  //   @media screen and (max-width: 390px) {
  //     font-size: 35px;
  //   }
  // }


  // h2 {
  //   font-family: Serif;
  //   color: var(--gradientDark);
  //   font-size: 65px;
  //   font-style: normal;
  //   line-height: 1.2;
  //   letter-spacing: 0.02em;
  //   @media screen and (max-width: 1400px) { 
  //     font-size: 60px;
  //   }
  //   @media screen and (max-width: 700px) {
  //     font-size: 48px;
  //   }
  //   @media screen and (max-width: 480px) {
  //     font-size: 25px;
  //   }
  //   @media screen and (max-width: 390px) {
  //     font-size: 25px;
  //   }
  // }

  h1 {
    font-family: Serif;
    color: var(--white);
    font-size: 3.75rem;
    text-shadow: 0px 0px 10px rgba(0, 19, 55, .5);

    line-height: 1.4;
    letter-spacing: 0.02em;

    @media screen and (max-width: 1536px) { 
      font-size: 3.2rem;
    }
       @media screen and (max-width: 768px) { 
      font-size: 2.6rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1.85rem;
    }
  }

  h2 {
    font-family: Serif;
    color: var(--gradientDark);
    font-size: 2.75rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.02em;
    @media screen and (max-width: 1536px) { 
      font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1.6rem;
    }
  }



  h3 {
    font-family: Serif;
    color: var(--navy);
    font-size: 2.5rem;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.02em;


    @media screen and (max-width: 1536px) { 
      font-size: 2.2rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.75rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1.2rem;
    }
    // @media screen and (max-width: 390px) {
    //   font-size: 19px;
    // }

  }

  h4 {
    font-family: Serif;
    font-size: 2.3rem;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.02em;

    @media screen and (max-width: 1536px) { 
      font-size: 2rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.75rem; 
    }
    @media screen and (max-width: 640px) {
      font-size: 1.5rem;
    }
        @media screen and (max-width: 390px) {
      font-size: 1.05rem;
    }
  }

  h5 {
    font-family: Serif;
    font-size: 2rem;
    font-style: normal;

    line-height: 1.4;
    letter-spacing: 0.05em;

    @media screen and (max-width: 1536px) { 
      font-size: 1.75rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1.2rem;
    }

  }

  h6 {
    font-family: Serif;
    font-size: 22px;
    font-style: normal;
    color: var(--blue);
    line-height: 1.4;
    letter-spacing: .1em;

    @media screen and (max-width: 1536px) { 
      font-size: 1.4rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1rem;
    }
  }

  ul, li, a {
    text-decoration: none;
    font-family: Montserrat;
    color: var(--navy2);
    font-size: 14px;
    font-style: normal;
    font-variant: all-small-caps;
    letter-spacing: .1em !important;

    @media screen and (max-width: 1025px) {
      font-size: 12px;
    }
    // @media screen and (max-width: 700px) {
    //   font-size: 12px;
    // }
  }

  button {
      //background-color: transparent;
      font-family: Montserrat;
      font-size: 1.1rem;
      font-weight: 400;
      @media screen and (max-width: 1536px) {
        font-size: .95rem;
      }
      @media screen and (max-width: 768px) {
        font-size: .85rem;
      }
      @media screen and (max-width: 640px) {
        font-size: .6rem;
      }

      & > * {
        font-family: Montserrat;
        font-size: 1.1rem;
      @media screen and (max-width: 1536px) {
        font-size: .95rem;
      }
      @media screen and (max-width: 768px) {
        font-size: .85rem;
      }
      @media screen and (max-width: 640px) {
        font-size: .6rem;
      }

      }

  }

  ul, ol {
    font-family: Montserrat;
  }

`;

export default Typography;
