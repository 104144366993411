import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #FBFBFB;
    --navy: #002B7C;
    --navy2: #001337;
    --navy80: rgba(0, 43, 124, .8);
    --lightBlue: #91f9ff;
    --blue: #0291DA;
    --green: #03ff2d; 
    --orange:#fcc43f ;
    --ultraLightBlue: #c2ebff;
    --gradientLight: linear-gradient(90deg, #c2ebff 25%, #24A0FF 56.46%);
    --gradientDark: linear-gradient(90deg, #019FED 5%, #0C2E6D 50%);
    --gradient: linear-gradient(90deg, rgba(255,206,3,1) 0%, rgba(28,200,0,1) 31%, rgba(2,172,255,1) 72%);
    --overlay: linear-gradient(180deg, rgba(251,251,251,.5) 0%, rgba(251,251,251,0.2) 37%, rgba(251,251,251,0) 63%);
    --tileIcon: #5182A7;
    --shadow: 0px 2px 10px 0px rgba(0, 19, 55, 0.2);
  }
  html {
    color: var(--navy2);
    min-height: 100%;
    //////added
    height: 100%;
    width: 100%;
    ///////
    overflow-x: clip; 
    overflow-y: scroll;
    /* scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth; */
    #gatsby-focus-wrapper { outline:none }

  }


  *,
  *::before,
  *::after {
  box-sizing: inherit;
  margin:0;
  padding:0;
}

  body {
    width:100%;
    box-sizing: border-box;
    margin:0;
    padding:0;
    overflow-x: hidden;
    overflow-y: clip;
    scroll-behavior: smooth;
    border:0;
    -webkit-font-smoothing: antialiased;
    font-smooth: auto;
    -moz-osx-font-smoothing: greyscale;
    background-color: var(--white);

    @media screen and (max-height: 600px){
      @media screen and (orientation: landscape ) {
        display: none;
      }
    }

    .gatsbyImgAssn {
      width: 400px !important;
      height: 100%;
      @media screen and (max-width: 500px){
        width: 250px !important;
      }
    }

    button {
      color: var(--navy2);
      cursor: pointer;
      -webkit-cursor: pointer;
    }

    
    ${
      '' /* &::-webkit-scrollbar {
      width: 0px;
      
    }
    
    &::-webkit-scrollbar-track {
      background-color: var(--white);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 159, 255, 0.7);
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 159, 255, 1);
    } */
    }
  
  }


  button {
    color: var(--navy);
    border: none;
    cursor: pointer;

  }

  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
  } */
  //styles for static image!!!
  div.gatsby-image-wrapper {
     height: 100%;
     width: 100vw;
  }

ul > * {
  list-style-type: lower-latin;
  list-style-position: outside;
}



`;

export default GlobalStyles;
