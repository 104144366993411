import React from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Navbar from '../components/Navbar/Navbar';
import InfoBar from '../components/Navbar/InfoBar';
import Footer from './Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Seo from './Seo';
import Cookie from './Cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Layout({ children }) {
  return (
    <section>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={true}
      />
      <InfoBar />
      <Navbar />
      <GlobalStyles />
      <Typography />
      {children}
      <Cookie />
      <Footer />
    </section>
  );
}
export const Head = () => <Seo />;
