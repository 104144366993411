import { Link } from 'gatsby';
import React, { useRef } from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--navy);
  width: 100vw;
  height: 25px;
  transition: transform 800ms ease;
  z-index: 1;
`;

const Text = styled.p`
  color: var(--white);
  display: inline;
  font-size: 12px;
  font-variant: small-caps;
  letter-spacing: 0.15em;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 700px) {
    font-size: 11px;
  }

  &.additional {
    color: var(--lightBlue);
    position: absolute;
    left: 100px;
    font-weight: 500;
    right: auto;
    opacity: 1;
    transition: opacity 300ms ease;

    @media screen and (max-width: 1300px) {
      left: 35px;
    }
    @media screen and (max-width: 1000px) {
      opacity: 0;
    }
  }
`;

const InnerButton = styled(Link)`
  all: unset;
  cursor: pointer;
  color: var(--lightBlue);
  font-weight: 500;
  position: relative;
  transition: all 500ms ease;

  & > a {
    all: unset;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 0.8px;
    background-color: var(--lightBlue);
    transform: scaleX(0);
    opacity: 0;
    transition: all 500ms ease;

    @media screen and (max-width: 700px) {
      bottom: 0px;
    }
  }

  &:hover::after {
    transform: scaleX(1);
    opacity: 1;
  }
`;

const InfoBar = () => {
  const infoRef = useRef();

  return (
    <BackgroundContainer ref={infoRef}>
      <Text className='additional'>skorzystaj z mojej oferty.</Text>
      <Text>
        zapisz się na bezpłatną konsultację już{' '}
        <InnerButton to='/kontakt/#contact-form'>dziś</InnerButton>
      </Text>
    </BackgroundContainer>
  );
};

export default InfoBar;
