/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logo from '/src/images/coaching_symbol.svg';
import Hamburger from './Hamburger';
import SocialMedia from '../SocialMedia';
import { nanoid } from 'nanoid';

const NavBg = styled.nav`
  width: 100%;
  height: 80px;
  background-color: ${({ isMobile }) =>
    isMobile ? '#fbfbfb90' : 'transparent'};
  box-shadow: ${({ isMobile }) =>
    isMobile
      ? '0px 2px 10px 0px rgba(0, 19, 55, 0.2)'
      : '0px 2px 10px 0px rgba(0, 19, 55, 0)'};
  backdrop-filter: ${({ isMobile }) => (isMobile ? 'blur(70px)' : 'blur(0)')};
  -webkit-backdrop-filter: ${({ isMobile }) =>
    isMobile ? 'blur(70px)' : 'blur(0)'};
  position: fixed; //absolute
  top: 25px;
  z-index: 1000;
  transition: all 300ms ease, height 800ms ease;
  transform-origin: top;

  & > * {
    transition: none;
  }

  @media screen and (max-width: 700px) {
    height: ${({ isOpen }) => (isOpen ? '100vh' : '60px')};
    background-color: ${({ isOpen, isMobile }) =>
      isOpen || isMobile ? '#fbfbfb90' : 'transparent'} !important;
    box-shadow: ${({ isOpen, isMobile }) =>
      isOpen || isMobile
        ? '0px 2px 10px 0px rgba(0, 19, 55, 0.2)'
        : '0px 2px 10px 0px rgba(0, 19, 55, 0)'} !important;
    backdrop-filter: ${({ isOpen, isMobile }) =>
      isOpen || isMobile ? 'blur(70px)' : 'blur(0)'} !important;
    -webkit-backdrop-filter: ${({ isOpen, isMobile }) =>
      isOpen || isMobile ? 'blur(70px)' : 'blur(0)'} !important;
  }
`;

const ListContainer = styled.ul`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: ${({ isMobile }) =>
    isMobile ? 'space-between' : 'flex-end'};
  align-items: center;
  gap: 60px;
  list-style: none;
  margin-right: 100px;
  margin-left: 100px;
  /* top: 0;
  left: 0; */
  padding-bottom: 5px;
  flex-wrap: nowrap;

  & > *:not(:last-child):not(:first-child) {
    text-decoration: none;
    font-weight: 500;
    padding: 10px !important;
    z-index: 2000;
  }

  & > * > * {
    color: var(--navy2);
  }

  & > a {
    cursor: pointer;
    transition: all 500ms ease;
    min-width: fit-content;
    padding: 0px 5px 0px 8px;
    color: var(--navy2);

    &:first-child {
      margin-left: 0px;
      margin-right: auto;
    }

    &:not(.hamburger):hover {
      transform: translateY(-3px);
    }
  }

  @media screen and (max-width: 1300px) {
    margin-right: 30px;
    margin-left: 30px;
  }

  @media screen and (max-width: 1025px) {
    gap: 30px;
  }

  @media screen and (max-width: 700px) {
    margin-right: 30px;
    margin-left: 30px;
    height: 60px;
  }
`;

const LogoLink = styled(Link)`
  margin-left: 100px;
  margin-right: auto;

  @media screen and (max-width: 1300px) {
    margin-left: 50px;
  }
`;

const Logo = styled.img`
  width: 70px !important;
  filter: contrast(1.3) grayscale(10%);

  @media screen and (max-width: 700px) {
    width: 50px !important;
  }
`;

const MobileListContainer = styled.ul`
  display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-around;
  margin-top: 30px;
  align-items: center;
  height: 50%;
  transition: all 1000ms ease;
  visibility: ${({ isOpen }) => (isOpen ? 'hidden' : 'visible')};
  opacity: 0;

  & > a {
    color: var(--navy2) !important;
  }

  & > * > * {
    color: var(--navy2) !important;
  }
`;

const SubBg = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  visibility: hidden;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(70px);
  -webkit-backdrop-filter: blur(70px);
  box-shadow: '0px 2px 10px 0px rgba(0, 19, 55, 0)';

  &::before {
    content: '';
    position: absolute;
    border: none;
    width: 100%;
    height: 2px;
    background: var(--gradient);
    left: 0;
    top: 0;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const SubBgContainer = styled.div`
  display: flex;
  gap: 50px;
  padding-right: 100px;
  justify-content: flex-end;
  align-items: center;
  & > a {
    cursor: pointer;
    padding-right: 0 !important;
    padding-left: 0 !important;
    font-family: Montserrat;
    text-transform: lowercase;
    font-variant: small-caps;
    min-width: fit-content;
    color: var(--white);
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
      transform: translateY(-3px);
    }
  }
  @media screen and (max-width: 1300px) {
    padding-right: 30px;
  }

  @media screen and (max-width: 700px) {
    padding-right: 30px;
  }
`;

const Navbar = () => {
  const mediaQuery =
    typeof window !== 'undefined' && window.matchMedia('(max-width: 700px)');
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && mediaQuery.matches
  );
  const [isClicked, setIsClicked] = useState(false);
  const [isProgramClicked, setIsProgramClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(isMobile ? false : true);

  const timeRef = useRef();
  const navRef = useRef(null);
  const subRef = useRef(null);

  useEffect(() => {
    let navOffsetTop = navRef.current.offsetTop;
    function fixedNavbar() {
      if (navRef.current && subRef.current) {
        subRef.current.style.transition = 'all 300ms ease'; //500
        subRef.current.style.opacity = 0;
        subRef.current.style.visibility = 'hidden';

        if (window.scrollY >= navOffsetTop) {
          setIsTransparent(false);
          navRef.current.style.position = 'fixed';
          navRef.current.style.top = '0';
          navRef.current.style.backgroundColor = '#fbfbfb80';
          navRef.current.style.backdropFilter = 'blur(70px)';
          navRef.current.style.webkitBackdropFilter = 'blur(70px)';
          navRef.current.style.boxShadow =
            '0px 2px 10px 0px rgba(0, 19, 55, 0.2)';
          setIsProgramClicked(false);
        } else {
          if (
            isMobile ||
            navRef.current.style.backgroundColor !== 'transparent'
          ) {
            setIsTransparent(false);
          } else {
            setIsTransparent(true);
          }
          navRef.current.style.position = 'fixed'; //absolute
          navRef.current.style.backgroundColor = 'transparent';
          navRef.current.style.boxShadow =
            '0px 2px 10px 0px rgba(0, 19, 55, 0)';
          navRef.current.style.top = '25px';
          navRef.current.style.backdropFilter = 'blur(0px)';
          navRef.current.style.webkitBackdropFilter = 'blur(0px)';
        }
      }
    }
    window.addEventListener('scroll', fixedNavbar);
  }, [isMobile]); //earlier []

  const handleSubMenu = () => {
    setIsProgramClicked((prevIsProgramClicked) => !prevIsProgramClicked);
  };

  useEffect(() => {
    if (isProgramClicked) {
      subRef.current.style.position = 'absolute';
      subRef.current.style.top = '80px';
      subRef.current.style.opacity = 1;
      subRef.current.style.height = '40px';
      subRef.current.style.visibility = 'visible';
      subRef.current.style.backgroundColor = 'var(--navy)';
      subRef.current.style.boxShadow = '0px 2px 10px 0px rgba(0, 19, 55, 0.2)';
      subRef.current.style.transform = 'translateX(0)';

      navRef.current.style.backdropFilter = 'blur(70px)';
      navRef.current.style.webkitBackdropFilter = 'blur(70px)';
      navRef.current.style.backgroundColor = '#fbfbfb90';
    } else {
      subRef.current.style.opacity = 0;
      subRef.current.style.height = '40px';
      subRef.current.style.visibility = 'hidden';
      subRef.current.style.transform = 'translateX(-100%)';
    }

    subRef.current.style.transition = 'all 800ms ease';
  }, [isProgramClicked]);

  const handleClick = () => {
    setIsClicked((p) => !p);
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    function handleListOrientation() {
      setIsMobile(mediaQuery.matches);
    }
    window.addEventListener('resize', handleListOrientation);
    return () => {
      window.removeEventListener('resize', handleListOrientation);
    };
  }, [mediaQuery.matches]); //earlier []

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        timeRef.current.style.opacity = 1;
      }, 200);
    }
  }, [isOpen]);

  const menu = {
    title: ['O mnie', 'Programy', 'Współpraca', 'Kontakt'],
    src: ['/o-mnie', '', '/współpraca', '/kontakt'],
  };

  const menuMobile = {
    title: [
      'O mnie',
      'Coaching odwagi',
      'Metamorfoza kariery',
      'Współpraca',
      'Kontakt',
    ],
    src: [
      '/o-mnie',
      '/coaching-odwagi',
      '/metamorfoza-kariery',
      '/współpraca',
      '/kontakt',
    ],
  };

  const subMenu = {
    title: ['Coaching odwagi', 'Metamorfoza kariery'],
    src: ['/coaching-odwagi', '/metamorfoza-kariery'],
  };

  const list = menu.src.map((src, index) => {
    const id = nanoid();
    if (index !== 1) {
      return (
        <Link
          key={id}
          to={index !== 1 ? src : undefined}
          onMouseDown={(e) => {
            if (index === 1) {
              e.preventDefault(); // Prevent the default behavior
              handleSubMenu();
            }
          }}
        >
          {menu.title[index]}
        </Link>
      );
    }
    // if (index === 1) {
    return <Link onMouseDown={handleSubMenu}>{menu.title[index]}</Link>;
    // }
  });

  const list2 = menuMobile.title.map((title, index) => {
    const id = nanoid();
    return (
      <Link
        key={id}
        fade='true'
        style={{
          padding: '10px',
          textDecoration: 'none',
          fontWeight: '500',
        }}
        to={menuMobile.src[index]}
        onClick={() => {
          setIsClicked(false);
          setIsOpen(false);
        }}
      >
        {title}
      </Link>
    );
  });

  const programs = subMenu.title.map((title, index) => {
    const id = nanoid();
    return (
      <Link
        key={id}
        fade='true'
        activeStyle={{
          color: 'var(--lightBlue)',
        }}
        style={{
          padding: '10px',
          textDecoration: 'none',
          fontWeight: '500',
        }}
        to={subMenu.src[index]}
      >
        {title}
      </Link>
    );
  });

  return (
    <NavBg
      ref={navRef}
      isOpen={isOpen}
      isMobile={isMobile}
      transparent={isTransparent}
    >
      <ListContainer
        isMobile={isMobile}
        isOpen={isOpen} // passing isOpen to ListContainer
        transparent={isTransparent}
      >
        <LogoLink
          fade='true'
          to='/'
          onClick={() => {
            setIsClicked(false);
            setIsOpen(false);
          }}
        >
          <Logo src={logo} />
        </LogoLink>
        {!isMobile ? list : null}
        {!isMobile ? <SocialMedia transparent={isTransparent} /> : null}
        {isMobile ? (
          <Hamburger
            className='hamburger'
            isClicked={isClicked}
            isOpen={isOpen}
            handleClick={handleClick}
          />
        ) : null}
      </ListContainer>
      <SubBg ref={subRef} isProgramClicked={isProgramClicked}>
        <SubBgContainer>{programs}</SubBgContainer>
      </SubBg>
      {isOpen ? (
        <MobileListContainer ref={timeRef}>
          {list2}
          <SocialMedia />
        </MobileListContainer>
      ) : null}
    </NavBg>
  );
};

export default Navbar;
