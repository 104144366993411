exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-odwagi-js": () => import("./../../../src/pages/coaching-odwagi.js" /* webpackChunkName: "component---src-pages-coaching-odwagi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-metamorfoza-kariery-js": () => import("./../../../src/pages/metamorfoza-kariery.js" /* webpackChunkName: "component---src-pages-metamorfoza-kariery-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/współpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */)
}

