import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import landing from '../images/landing.png';

const Seo = ({ title, description, pathname: path, children }) => {
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    instagramUsername,
    facebookUsername,
    linkedinUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${path || ''}`,
    instagramUsername,
    facebookUsername,
    linkedinUsername,
  };
  return (
    <>
      <title>{seo.title}</title>
      <meta
        name='google-site-verification'
        content='iUrDL4PLiK8nG7kZrNCDuVDwMjhWiKXiN8KYLoVjdQM'
      />

      <meta name='description' content={seo.description} />
      <meta
        property='og:title'
        content={'Coaching Przyszłości - life & business coach'}
      />
      <meta property='og:image' content={landing} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={seo.url} />
      {seo.instagramUsername && (
        <meta
          property='og:instagram'
          content={`https://instagram.com/${seo.instagramUsername}`}
        />
      )}
      {seo.facebookUsername && (
        <meta
          property='og:facebook'
          content={`https://facebook.com/${seo.facebookUsername}`}
        />
      )}
      {seo.linkedinUsername && (
        <meta
          property='og:linkedin'
          content={`https://www.linkedin.com/in/${seo.linkedinUsername}`}
        />
      )}
      <link rel='canonical' href={seo.url} />
      <link rel='apple-touch-icon' href={'../images/coaching_symbol.svg'} />
      {children}
    </>
  );
};
export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        instagramUsername
        facebookUsername
        linkedinUsername
      }
    }
  }
`;
