import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'gatsby';
import SocialMedia from '../components/SocialMedia';
import logo from '/src/images/logo-cp-white-web.png';
import { StaticImage } from 'gatsby-plugin-image';

const Container = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--navy);
  padding: 40px 200px 0px 200px;
  overflow: hidden;

  @media screen and (max-width: 2050px) {
    padding: 40px 100px 10px 130px;
  }

  @media screen and (max-width: 1536px) {
    padding: 40px 120px 10px 120px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 100px 10px 100px;
  }
  @media screen and (max-width: 640px) {
    padding: 40px 50px 10px 50px;
  }

  &::before {
    content: '';
    position: absolute;
    border: none;
    width: 100%;
    height: 4px;
    background: var(--gradient);
    left: 0;
    top: 0;
  }

  & > *:not(*:last-child) {
    z-index: 1;
  }

  & > *:last-child {
    position: absolute;
    left: -12%;
    bottom: 0;
    border: 1px solid red;
    z-index: 0;
    opacity: 0.04;
    width: 100%;

    @media screen and (max-width: 640px) {
      left: -20%;
      bottom: -30%;
    }
  }
`;

const MyLink = styled(Link)`
  z-index: 100;
  color: var(--white);
  font-size: 9px;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (min-width: 1500px) {
    font-size: 11px;
  }
`;
const Copyright = styled.p`
  color: var(--lightBlue);
  opacity: 0.8;
  font-size: 9px;
  text-align: center;
  z-index: 50;

  @media screen and (min-width: 1500px) {
    font-size: 11px;
  }

  @media screen and (max-width: 900px) {
    order: 2;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @media screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 300px;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  & > * {
    display: flex;
    align-items: center;
    height: 18px;
    width: 100%;
  }

  & > *:nth-child(2) {
    justify-content: center;
  }

  & > *:first-child {
    justify-content: center;
  }
`;

const Footer = () => {
  const Year = new Date();

  return (
    <>
      <Parallax
        translateY={[30, 0, 'easeInOut']}
        shouldAlwaysCompleteAnimation={true}
      >
        <Container>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <SocialMedia
            size={{ color: 'var(--lightBlue)', padding: '30px 0px 10px 0px' }}
          />
          <SubContainer>
            <p />
            <Copyright>
              Copyright © {Year.getFullYear()} Coaching Przyszłości. All rights
              reserved.
            </Copyright>
            <LinksContainer>
              <MyLink to='/polityka-prywatnosci'>polityka prywatności</MyLink>
              <MyLink to='/polityka-prywatnosci/#warunki'>warunki</MyLink>
            </LinksContainer>
          </SubContainer>

          <StaticImage
            src='../images/logoPic.png'
            alt='Pani Życia.'
            placeholder='dominantColor'
            layout='constrained'
            formats={['auto', 'webp', 'avif']}
            objectFit='cover'
            //for d4
            objectPosition={'left 30%'}
          />
        </Container>
      </Parallax>
    </>
  );
};

export default Footer;
