import React from 'react';
import styled from 'styled-components';

const HamburgerIcon = styled.button`
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > * {
    background: var(--navy2);
    width: 25px;
    height: 2px;
    transition: all 500ms ease;
    content: '';
    position: relative;
    transform-origin: center;
  }

  & > *:first-child {
    transform: ${({ clicked }) =>
      clicked
        ? 'translateY(6.75px) rotate(-45deg)'
        : 'translateY(0px) rotate(0deg)'};
  }

  & > *:nth-child(2) {
    opacity: ${({ clicked }) => (clicked ? '0' : '1')};
    transform: ${({ clicked }) =>
      clicked ? 'translateX(20px)' : 'translateX(0px)'};
  }

  & > *:last-child {
    transform: ${({ clicked }) =>
      clicked
        ? 'translateY(-7px) rotate(45deg)'
        : 'translateY(0px) rotate(0deg)'};
  }
`;

const Hamburger = ({ handleClick, isClicked }) => {
  return (
    <HamburgerIcon onClick={handleClick} clicked={isClicked}>
      <div />
      <div />
      <div />
    </HamburgerIcon>
  );
};

export default Hamburger;
