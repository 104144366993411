import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { LiaCookieBiteSolid } from 'react-icons/lia';
import styled from 'styled-components';

const Icon = styled(LiaCookieBiteSolid)`
  font-size: clamp(2rem, 2.5rem, 4rem);
  color: var(--orange);
  display: inline;
`;

const Cookie = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Akceptuję'
      declineButtonText='Odrzucam'
      enableDeclineButton
      cookieName='gatsby-plugin-gdpr-cookies'
      style={{
        background: 'var(--navy)',
        fontFamily: 'Montserrat',
        fontWeight: 450,
        fontVariant: 'all-small-caps',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
      declineButtonStyle={{
        color: 'var(--navy)',
        background: 'var(--white)',
        padding: '.75rem 1.5rem',
        borderRadius: '999rem',
        fontWeight: 500,
      }}
      buttonStyle={{
        color: 'var(--navy)',
        background: 'var(--white)',
        padding: '.75rem 1.5rem',
        borderRadius: '999rem',
        fontWeight: 500,
      }}
      expires={150}
    >
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <Icon />
        <p>
          Niniejsza strona wykorzystuje pliki cookie.{' '}
          <a
            href='/polityka-prywatnosci/#cookie'
            style={{
              fontSize: 'inherit',
              color: 'var(--lightBlue)',
              cursor: 'pointer',
            }}
          >
            <span>Dowiedz się wiecej</span>
          </a>
        </p>
      </div>
    </CookieConsent>
  );
};

export default Cookie;
